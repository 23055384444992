

html {
  /* '!important' is needed because this value is overriden by MUI modal components */
  overflow-y: scroll !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

body {
  margin: 0;
  background-color: white;
  font-family: 'Maven Pro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: auto; */
}

a {
  color: inherit;
}

code {
  font-family: 'Maven Pro';
}

#root {
  position: absolute;
  width: 100%;
  height: 100%;
}
